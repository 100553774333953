import { translit } from '../../../ui/src/utils/translit'

export function getPatientFullName ({ lastName, firstName, patronymic }) {
  return [lastName, firstName, patronymic].filter((item) => !!item).join(' ')
}


export function makeSlug (text) {
  const regexp = /[a-zA-Z0-9а-яА-Я-_\s]+/g
  const allowedString = (text.match(regexp) || []).join('')
  return translit(
    allowedString
      .replace(/\s+/g, ' ')
      .replace(/\s/g, '-').toLowerCase()
  )
}
